import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Gallery from '../components/Gallery'


import mypic from '../assets/images/Mary.jpg'
import resume from '../assets/files/resume.pdf'


const About = () => (
    <Layout>
        <Helmet>
            <title>Hi, I'm Mary! - Mary Ojo</title>
            <meta name="description" content="About Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Meet Me</h1>
                    </header>
                    <div className='grid-wrapper'>
                        <div className='col-12 layout1 '>
                            <div className="image left mypic col-3"><img className="pic" src={mypic} alt="" /></div>
                            <div>
                            <div className='col-9 intro'>
                            <p>Hi, I'm Mary!<br/>
                            <br/>
                            In the past years, I have had experience working with individual clients, startups and established organisations in design + engineering. I'm really passionate about technology. 
                            {/* <br/> */}
                            From my perspective, digital experiences greatly impact the problem-solving process. This is one reason why I care so much about creating products that are innovative, inclusive and human-centered. I believe that digital experiences should convey humanness and be easy to use for everyone. 
                            </p>
                            <p>It's so nice to have you here! Send me a message if you have any feedback, comments or questions.</p>
                            <p>Thank you!</p> 
                            <ul className="actions">
                                <li><a href={resume} className="button icon fa-eye" target="_blank">View resume</a></li>
                            </ul>
                            </div>
                            
                            </div>
                       
                        </div>
                    </div>
                    <hr className="major" />
                    
                        <div className="box alt">
                                    <h3>My Skills</h3>
                                    {/* <Skills/> */}
                                    <div className='skillset intro'>
                                        <div>HTML/CSS</div>
                                        <div>JavaScript</div>
                                        <div>React.js</div>
                                        <div>Figma</div>
                                        <div>Framer</div>
                                        <div>Miro</div>
                                        <div>Maze</div>
                                        <div>UI Prototyping</div>
                                        <div>User Research</div>
                                        <div>Wordpress</div>
                                        <div>Wix</div>
                                        <div>Bubble</div>
                                        <div>Weebly</div>
                                        <div>Shopify</div>
                                    </div>
                                    </div>
                                    {/* <hr className="major" />

                        <div className="box alt">
                                    <h3>Picture Stories</h3>
                                    <Gallery/>
                        </div> */}
                    {/* </div> */}
                </div>
            </section>
        </div>

    </Layout>
)

export default About